import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import Vant from "vant";
import "vant/lib/index.less";
import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(SlideVerify);
Vue.config.ignoredElements = [...Vue.config.ignoredElements, 'wx-open-launch-weapp']
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

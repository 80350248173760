import Main from "../views/Main.vue"
export const asyncRouterMap = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/userLogin/Login.vue"),
        meta: { title: "登录" },
    },
    {
        path: "/regist",
        name: "regist",
        component: () => import("@/views/Agent/regist.vue"),
        meta: { title: "注册成为代理商" },
    },
    {
        path: "/",
        component: Main,
        //meta: { title: "首页" },
        children: [
            {
                path: "registerAgent",
                name: "registerAgent",
                component: () => import("@/views/Agent/index.vue"),
                meta: { title: "代理商简介" },
            },
            {
                path: "registerTeacher",
                name: "registerTeacher",
                component: () => import("@/views/Agent/teacher.vue"),
                meta: { title: "教师推广简介" },
            },
            {
                path: "registerStudent",
                name: "registerStudent",
                component: () => import("@/views/Agent/student.vue"),
                meta: { title: "学生推广简介" },
            },
        ],
    },

];


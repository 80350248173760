import Vue from "vue";
//路由
import VueRouter from "vue-router";
import { asyncRouterMap } from "../config/router.config";

// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
    return VueRouterReplace.call(this, location).catch(err => err)
}


Vue.use(VueRouter);
const routes = []
const router = new VueRouter({
    routes: routes.concat(asyncRouterMap),

    mode: "history",
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    //console.log(to)
    //console.log(from)
    /*if (from.name != null || to.name != "sharePage"){
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        localStorage.setItem("isiOS", isiOS)
    }*/
    next()
    /*if (to.path.toLowerCase() !== "/login" && to.path.toLowerCase() !== "/register" && to.path.toLowerCase() !== "/forgetpwd" && to.path.toLowerCase() !== "/sharepage") {
        if (window.sessionStorage.getItem("userToken") !== null) {
            next()
        } else {
            next({path: "/login"})
        }
    }else{
        next()
    }*/
})
/*router.afterEach((to, from) =>{
    let baseLocation = "https://zxzw-web.yyetes.com"
    if (window.__wxjs_is_wkwebview) { // IOS
        if (window.entryUrl == '' || window.entryUrl == undefined) {
            var url = baseLocation  +
                to.fullPath
            window.entryUrl = url
            console.log('IOS', window.entryUrl)
        } else {
            console.log('IOS2', window.entryUrl)
        }
    } else { // 安卓
        window.entryUrl = baseLocation +
            to.fullPath
    }
})*/
export default router;
// 现在你可以在任何地方访问historyRoutes数组来获取历史路由信息
